html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-size: 62.5%;
}

body {
  color: #d9d8dc;
  background-color: #170217;
  max-width: 38em;
  margin: auto;
  padding: 13px;
  font-size: 1.8rem;
  line-height: 1.618;
}

@media (width <= 684px) {
  body {
    font-size: 1.53rem;
  }
}

@media (width <= 382px) {
  body {
    font-size: 1.35rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-weight: 700;
  line-height: 1.1;
}

h1 {
  font-size: 2.35em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

small, sub, sup {
  font-size: 75%;
}

hr {
  border-color: #fbdafb;
}

a {
  color: #fbdafb;
  text-decoration: none;
}

a:visited {
  color: #f5abf7;
}

a:hover {
  color: #f5abf7;
  border-bottom: 2px solid #d9d8dc;
}

ul {
  margin-top: 0;
  margin-bottom: 2.5rem;
  padding-left: 1.4em;
}

li {
  margin-bottom: .4em;
}

blockquote {
  background-color: #40363a;
  border-left: 5px solid #fbdafb;
  margin-bottom: 2.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: .8em .8em .8em 1em;
}

blockquote p {
  margin-bottom: 0;
}

img, video {
  max-width: 100%;
  height: auto;
  margin-top: 0;
  margin-bottom: 2.5rem;
}

pre {
  background-color: #40363a;
  margin-top: 0;
  margin-bottom: 2.5rem;
  padding: 1em;
  font-size: .9em;
  display: block;
  overflow-x: auto;
}

code, kbd, samp {
  white-space: pre-wrap;
  background-color: #40363a;
  padding: 0 .5em;
  font-size: .9em;
}

pre > code {
  white-space: pre;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table {
  text-align: justify;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border-bottom: 1px solid #40363a;
  padding: .5em;
}

input, textarea {
  border: 1px solid #d9d8dc;
}

input:focus, textarea:focus {
  border: 1px solid #fbdafb;
}

textarea {
  width: 100%;
}

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  text-align: center;
  white-space: nowrap;
  color: #170217;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fbdafb;
  border: 1px solid #fbdafb;
  border-radius: 1px;
  padding: 5px 10px;
  text-decoration: none;
  display: inline-block;
}

.button[disabled], button[disabled], input[type="submit"][disabled], input[type="reset"][disabled], input[type="button"][disabled] {
  cursor: default;
  opacity: .5;
}

.button:focus:enabled, .button:hover:enabled, button:focus:enabled, button:hover:enabled, input[type="submit"]:focus:enabled, input[type="submit"]:hover:enabled, input[type="reset"]:focus:enabled, input[type="reset"]:hover:enabled, input[type="button"]:focus:enabled, input[type="button"]:hover:enabled {
  color: #170217;
  background-color: #f5abf7;
  border-color: #f5abf7;
  outline: 0;
}

textarea, select, input {
  color: #d9d8dc;
  box-shadow: none;
  box-sizing: border-box;
  background-color: #40363a;
  border: 1px solid #40363a;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 6px 10px;
}

textarea:focus, select:focus, input:focus {
  border: 1px solid #fbdafb;
  outline: 0;
}

input[type="checkbox"]:focus {
  outline: 1px dotted #fbdafb;
}

label, legend, fieldset {
  margin-bottom: .5rem;
  font-weight: 600;
  display: block;
}

html, body {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
}

input[type="checkbox"], input[type="radio"] {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
}

input[type="checkbox"]:checked + .toggle, input[type="radio"]:checked + .toggle {
  border-bottom: 2px solid #f07df2;
}

.toggle {
  box-sizing: border-box;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #e837eb;
  background: none;
  border: none;
  border-radius: 2px;
  width: 48px;
  height: 48px;
  padding: 5px 10px;
  line-height: 2;
  transition: all .4s;
  display: inline-block;
}

@media (hover: none) {
  button:hover:enabled, button:focus:enabled {
    background: none;
  }

  button:active:enabled {
    background: #e837eb;
    border-color: #e837eb;
  }
}

@media (hover: hover) {
  button:hover:enabled, button:focus:enabled, button:hover, button:focus {
    background: #f07df2;
    border-color: #f07df2;
  }

  .toggle:hover {
    cursor: pointer;
    background: #f07df2;
  }
}

input[type="text"] {
  width: 100%;
}

button:active:enabled {
  background: #e837eb;
  border-color: #e837eb;
}

.toggle:active {
  background: #e837eb;
}

button {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #e837eb;
  color: #d9d8dc;
  background: none;
  border: 2px solid #f07df2;
  border-radius: 2px;
  width: 100%;
  height: 48px;
  transition: all .4s;
}

.toggle-row {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: nne;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

input[type="range"], input[type="range"]:active:enabled, input[type="range"]:hover:enabled, input[type="range"]:focus:enabled {
  -webkit-appearance: none;
  background: none;
  border: none;
  border-radius: 2px;
  outline: 0;
  width: 100%;
  height: 32px;
  padding: 0;
  transition: all .4s;
}

input[type="range"]:focus:enabled, input[type="range"]:hover:enabled, input[type="range"]:active:enabled {
  border-color: #f07df2;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: auto;
  cursor: pointer;
  background: #170217;
  border: 2px solid #f07df2;
  border-radius: 24px;
  outline: none;
  width: 24px;
  height: 24px;
  transition: all .4s;
}

input[type="range"]::-moz-range-thumb {
  appearance: auto;
  cursor: pointer;
  background: #170217;
  border: 2px solid #f07df2;
  border-radius: 24px;
  outline: none;
  width: 24px;
  height: 24px;
  transition: all .4s;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #f07df2;
  border: 2px solid #170217;
}

input[type="range"]::-moz-range-thumb:hover {
  background: #f07df2;
  border: 2px solid #170217;
}

input[type="range"]::-webkit-slider-thumb:active {
  background: #e837eb;
  border: 2px solid #170217;
}

input[type="range"]::-moz-range-thumb:hover {
  background: #e837eb;
  border: 2px solid #170217;
}

input[type="range"]::-moz-range-track {
  appearance: auto;
  background: linear-gradient(#f5abf7 0 0) 50% / 100% 2px no-repeat;
  border: none;
  padding: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  appearance: auto;
  background: linear-gradient(#f5abf7 0 0) 50% / 100% 2px no-repeat;
  border: none;
  padding: 0;
}

fieldset {
  border: none;
}

label, legend {
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 300;
}

/*# sourceMappingURL=index.257eac87.css.map */
