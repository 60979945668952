$Overglow-1: rgba(240, 125, 242, 1); 
$Overglow-2: rgba(105, 101, 216, 1); 
$Overglow-3: rgba(0, 2, 38, 1); 
$Overglow-4: rgba(22, 36, 114, 1); 
$Overglow-5: rgba(29, 100, 242, 1); 

$Overglow-6: #19c74e;

$color-primary: $Overglow-1;
$color-force: $color-primary;
$color-blossom: lighten($color-force, 20%);
$color-fade: lighten($color-primary, 10%);
$color-emphasis: darken($color-primary, 15%);

$color-bg: darken($color-primary, 67%);
$color-bg-alt: #40363a;

/* $color-text: #dedce5; */
$color-text: #d9d8dc;
$font-size-base: 1.8rem;

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$font-family-heading: $font-family-base;

@import "sakura";

html, body {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
}

input[type="checkbox"], input[type="radio"] {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

input[type="checkbox"]:checked + .toggle,
input[type="radio"]:checked + .toggle
{
  border-bottom: 2px solid $color-primary;
}

.toggle {
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  text-align: center;
  line-height: 2;

  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
  
  -webkit-tap-highlight-color: $color-emphasis;

  height: 48px;
  width: 48px;
  background: none;
  border: none;
  transition: .4s;
  border-radius: 2px;
}

@media (hover: none) {
  button:hover:enabled, button:focus:enabled {
    background: none;
  }

  button:active:enabled {
    background: $color-emphasis;
    border-color: $color-emphasis;
  }
}

@media (hover: hover) {
  button:hover:enabled,
  button:focus:enabled, 
  button:hover, 
  button:focus {
    background: $color-primary;
    border-color: $color-primary;
  }

  .toggle:hover {
    background: $color-primary;
    cursor: pointer;
  }
}

input[type="text"] {
  width: 100%;  
}

button:active:enabled {
  background: $color-emphasis;
  border-color: $color-emphasis;
}

.toggle:active {
  background: $color-emphasis; 
}

button {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
  -webkit-tap-highlight-color: $color-emphasis;
  
  width: 100%;
  height: 48px;
  background: none;
  color: $color-text;
  border: 2px solid $color-primary;
  border-radius: 2px;
  transition: .4s;
}

.toggle-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: nne;
}

input[type="range"], input[type="range"]:active:enabled, input[type="range"]:hover:enabled, input[type="range"]:focus:enabled {
  -webkit-appearance: none;
  width: 100%;
  height: 32px;
  background: none;
  border: none;
  outline: 0;
  transition: .4s;
  padding: 0;
  border-radius: 2px;
}

input[type="range"]:focus:enabled, input[type="range"]:hover:enabled, input[type="range"]:active:enabled {
  border-color: $color-primary;
}

input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb 
{
  appearance: auto;
  border-radius: 24px;
  width: 24px; 
  height: 24px; 
  background: $color-bg; 
  outline: none;
  border: 2px solid $color-primary;
  cursor: pointer;
  transition: .4s;
}

input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-moz-range-thumb:hover
{
  border: 2px solid $color-bg;
  background: $color-primary;
}

input[type="range"]::-webkit-slider-thumb:active,
input[type="range"]::-moz-range-thumb:hover
{
  border: 2px solid $color-bg;
  background: $color-emphasis;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-webkit-slider-runnable-track
 {
  appearance: auto;
  background: linear-gradient($color-fade 0 0) 50%/100% 2px no-repeat;
  border: none;
  padding: 0;
}

fieldset {
  border: none;
}

label, legend {
  font-weight: 300;
  text-transform: uppercase;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}
